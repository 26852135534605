.shedule_header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.edit_button {
    white-space: nowrap;
    font-size: 12px!important;
    min-height: 30px!important;
}

.edit_button svg {
    display: none;
}

.edit_steams_form {
    width: 100%;
}

.edit_steams_form_group {
    width: 100%!important;
}

.edit_steams_input {
    width: 500px;
}